interface TagManagerEvent {
    event?: string;
    [propName: string]: any;
}

declare const dataLayer: Array<TagManagerEvent>;

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $gtm: {
            triggerEvent(options: TagManagerEvent),
            updateDataLayerVariable(options: TagManagerEvent)
        }
    }
}

export default class VueGoogleTagManager {
    static install(Vue, options?: any) {
        Vue.$gtm = {
            triggerEvent(options: TagManagerEvent) {
                if (typeof dataLayer !== 'undefined' && options?.event !== '') {
                    dataLayer.push(options);
                }
            },
            updateDataLayerVariable(options: TagManagerEvent) {
                if (typeof dataLayer !== 'undefined' && options) {
                    dataLayer.push(options);
                }
            }
        };
    }
}
